const initial = {};

// eslint-disable-next-line default-param-last
const projectDashboardCards = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_DASHBOARD_CARDS_FETCH_SUCCESS': {
      const { projectId, projectDashboardCards } = action;

      return { ...state, [projectId]: projectDashboardCards };
    }
    case 'WORKSPACE_PROJECT_DASHBOARD_CARDS_FETCH_SUCCESS': {
      const { projectDashboardCards } = action;

      return { ...state, workspaceProjectDashboardCards: projectDashboardCards };
    }

    default:
      return state;
  }
};

export default projectDashboardCards;
